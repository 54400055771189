.c-socials{
  list-style: none;
  line-height: 0;

  &__link{
    color: #ffffff;
    font-size: 0.75rem;

    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
  }

  &__item{
    display: inline-block;
    margin-right: 8px;
    line-height: 1;
    width: 30px;
    height: 30px;
    background: $theme-accent;

    border: 1px solid $theme-accent;

    text-align: center;

    transition: all 0.3s;

    &:hover{

      background-color: #ffffff;

      .c-socials__link{
        color: $theme-accent;
      }
    }
  }


}
