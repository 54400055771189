.c-main-header{

  background-image: url("../img/hero-pattern.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &__nav-wrapper{
    padding-top: 30px;
    padding-bottom: 30px;

    @include menu-not-collapsed{
      padding-top: 25px;
      padding-bottom: 55px;
      min-height: 145px;
    }
  }

  &--fullwidth{
    background-size: cover;
    background-attachment: fixed;
    min-height: 60vh;

    color: $color-white-1;

    padding-bottom: 100px;

    @include phone-only{
      min-height: 100vh;
    }

    @include tablet-landscape-down{
      background-color: #151515;
      background-image: none !important;
    }
  }
}
