.c-card{

  position: relative;

  &__item{
    margin-bottom: 5px;
    display: inline-block;
    min-width: 33%;

    background: $color-bg-card;
    overflow: hidden;

    position: relative;
    height: auto;

    cursor: pointer;

    @include phone-only{
      height: 100vh;
    }

    &:before{
      content: "";
      background: $theme-accent;
      display: block;
      width: 200%;
      height: 200%;

      position: absolute;
      bottom: -200%;
      right: -200%;

      border-radius: 50%;

      transition: all 0.5s ease-out;
    }

    &:hover{
      color: $color-text-card--hover;

      &::before{
        bottom: -50%;
        right: -50%;
      }

      .c-card__header, .c-card__icon {
        color: $color-text-card--hover;
      }
    }

    // MODIFICATORS
    &--text-only{

      background-color: $theme-accent;
      background-repeat: no-repeat;
      background-size: cover;

      .c-card__text{
        color: $color-text-card--hover;
        font-style: italic;
        font-weight: $weight-bold;
        font-family: $font-headings;
        font-size: 1.125rem;
        line-height: 1.55;
      }

      &:hover{
        &::before{
          display: none;
        }
      }
    }

    &--modal{
      position: fixed;
      height: 100vh;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 101;

      background: $color-bg-card;

      overflow: hidden;

      @include desktop-up{
        position: absolute;
        height: auto;
      }

      &:hover{
        color: $text-color;

        .c-card__icon {
          color: $theme-accent;
        }
        .c-card__header {
          color: $color-headings-secondary;
        }

        &::before{
          display: none;
        }
      }
    }
  }

  &__sizer{
    display: block;
    margin-top: 102.5%;
  }

  &__inner{
    padding-left: 50px;
    padding-right: 50px;
    z-index: 10;
    text-align: center;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__modal-content{

    display: none;

    padding-right: 15px;
    padding-left: 15px;


    @include phone-only{
      overflow-y: scroll;
    }

    @include tablet-portrait-up{
      padding-right: 60px;
      padding-left: 60px;
    }
    @include desktop-up{
      padding-right: 120px;
      padding-left: 120px;
    }
  }

  &__icon{
    margin-bottom: 28px;
    color: $theme-accent;
    font-size: 2.25rem;
    transition: all 0.3s;

    line-height: 1;
  }

  &__header{
    margin-bottom: 10px;
    transition: all 0.3s;
  }

  &__text{
    margin-bottom: 10px;
    transition: all 0.3s;
  }

  &__button{
    position: absolute;
    bottom: 0;
    right: 0;

    width: 84px;
    height: 84px;

    color: $color-text-card--hover;
    background-image: $grad-button-card;
  }

  &__button-field{
    cursor: pointer;
    position: absolute;
    bottom: 10px;
    right: 13px;
    z-index: 10;

    padding: 0;
    text-align: center;

    box-shadow: none;
    color: $color-text-card--hover;
    border: none;
    background: none;
    outline: none;

    &::after{
      content: "";
      display: block;

      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -12px;

      border: 1px solid $color-text-card--hover;
      border-radius: 50%;
      width: 24px;
      height: 24px;
    }

    &::before{
      content: "\eb8b";
      font-family: 'icofont';
      font-size: 18px;
      line-height: 1;
    }

    &--is-active{
      &::before{
        content: "\efb5";
      }
    }
  }

  &__caption{
    font-size: 0.75rem;
  }

  &__link{
    display: block;

    font-size: 0.75rem;
    color: $theme-accent;
    position: relative;
    text-decoration: underline;

    margin-bottom: 5px;

    &:hover{
      text-decoration: none;
    }
  }

  &__link-caption{
    margin-bottom: 5px;
  }
}
