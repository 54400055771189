.c-form{

  label{
    margin-bottom: 7px;
    display: block;
    font-family: $font-headings;
    font-size: 0.875rem;
    line-height: 1.2888;
    font-weight: $weight-bold;
  }

  input, textarea{
    margin-bottom: 15px;

    display: inline-block;
    width: 100%;
    outline: none;

    padding: 9px 0px 8px 10px;

    font-size: 0.75rem;
    line-height: 1.75;

    border: 1px solid $color-form-border;

    &:placeholder{
      color: $color-form-placeholder;
    }

    &:valid{
      box-shadow: $shadow-form-valid;
    }

    &[data-touched]:invalid{
      box-shadow: $shadow-form-invalid;

    }
  }

  textarea{
    resize: vertical;
    min-height: 90px;
  }
}
