// Theme variables

///////////////////////////////////////////////////
// Internal
$color-blue-1: #2066a1; // theme

$color-gold-1: #d3b484; // theme

$color-green-1: #8cb994; // theme, also validation
$color-green-2: #78a580; // tabs

$color-deepblue-1: #132a46; //h1,h2, main header

$color-black-1: #272626; // h3,h4

$color-darkgray-1: #2d2d2d;
$color-darkgray-2: #505050;

$color-gray-1: #b7b7b7; // form border
$color-gray-2: #aeaeae; // form placeholder
$color-gray-3: #f5f5f5; // cards background
$color-gray-4: #f8f8f8; // article background
$color-gray-5: #8d8d8d; // footer

$color-red-1: #f1a7a7; // invalid form

$color-white-1: #ffffff;
$color-white-2: #fefefe;

///////////////////////////////////////////////////
// Global
$theme-accent: $color-blue-1 !default;

$weight-normal: 400;
$weight-bold: 700;
$weight-black: 900;

$font-text: "Lato", sans-serif;
$font-headings: "Lora", sans-serif;

$font-family-base: $font-text;
$font-size-base: 100%;
$line-height-base: 1.5;

$text-color: $color-darkgray-2;
$text-color-header--fullwidth: $color-white-1;
$body-bg: #ffffff;

$color-form-border: $color-gray-1;
$color-form-placeholder: $color-gray-2;

$shadow-form-valid: inset 0px 0px 6px 0px $color-green-1;
$shadow-form-invalid: inset 0px 0px 6px 0px $color-red-1;

$color-link: $color-deepblue-1;
$color-link--hover: $theme-accent;

$color-link-nav: $color-darkgray-2;
$color-link-nav--alt: $color-white-1;
$color-link-nav--active: $color-deepblue-1;

$color-headings-primary: $color-deepblue-1;
$color-headings-secondary: $color-black-1;

$color-bg-card: $color-gray-3;
$color-text-card--hover: $color-white-2;
$grad-button-card: linear-gradient(135deg, transparent 0%, transparent 51%, $theme-accent 51%, $theme-accent 100%);

$color-bg-article: $color-gray-4;

$color-text-button-primary: $color-white-1;
$color-text-button-primary--hover: $text-color;

$color-text-footer: $color-gray-5;

///////////////////////////////////////////////////
// Transitions
$time-main-nav-transitions: 1s;

///////////////////////////////////////////////////
// Grid
$grid-sm: 600px;
$grid-md: 900px;
$grid-lg: 1200px;

///////////////////////////////////////////////////
// Plugins
