.u-visuallyhidden {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;

  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;

  top: 0;
  right: 50%;
}

// Positioning
.u-text-right{
  text-align: right;
}

// Margins
.u-mb-5{
  margin-bottom: 5px;
}

.u-mb-10{
  margin-bottom: 10px;
}

.u-mb-20{
  margin-bottom: 20px;
}

.u-mb-30{
  margin-bottom: 30px;
}

.u-mb-35{
  margin-bottom: 35px;
}

// Display
.u-block{
  display: block;
}
