.c-tabs{

  display: flex;
  flex-wrap: wrap;
  position: relative;

  transition: margin 0.3s;

  @include tablet-portrait-up{
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  &__item{
    width: 100%;
  }

  &__list{
    list-style: none;

    li{
      margin-bottom: 25px;
      padding-left: 50px;
      position: relative;

      &:before{
        content: "\ef8f";
        font-family: "icofont";
        position: absolute;
        top: 0;
        left: 25px;
        color: $theme-accent;
      }

      &:last-child{
        margin-bottom: 0;
      }
    }
  }

  &__header{
    margin-left: 50px;
    display: inline-block;
    font-family: $font-headings;
    font-weight: $weight-bold;
    color: $color-headings-primary;

    cursor: pointer;
    position: relative;

    padding-bottom: 5px;

    @include tablet-portrait-up{
      margin-left: 0;
      display: block;
      text-align: center;
    }

    &:before{
      content: "";
      position: absolute;
      background: $theme-accent;
      display: block;

      bottom: 0;
      left: 50%;
      right: 50%;
      height: 2px;

      transition: all 0.3s;
    }
  }

  [id^="tab"]:checked{

    & ~ .c-tabs__content{
      display: block;
    }

    & ~ .c-tabs__header{
      &:before{
        left: 0;
        right: 0;
      }
    }
  }

  &__content{
    position: absolute;

    padding-top: 30px;
    left: 0;
    width: 100%;
    display: none;

    text-align: initial;

    @include tablet-portrait-up{
      padding-top: 60px;
    }
  }
}
