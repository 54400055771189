.c-main-nav{

  position: relative;
  min-height: 35px;

  transition: transform 1s, opacity 1s;

  @include menu-not-collapsed{
    position: static;
  }

  &__list{
    list-style: none;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;

    padding-top: 60px;
    width: calc(100% + 30px);
    height: 100vh;

    transform: translateY(-100%);
    position: absolute;
    top: -30px;
    left: -15px;
    z-index: 100;

    opacity: 0;

    transition: transform 0.5s;

    @include menu-not-collapsed{
      padding-top: 0;
      height: auto;
      position: static;
      justify-content: space-between;
      flex-direction: row;
      transform: none;
      height: auto;
      overflow: visible;
      opacity: 1;
      width: 100%;
    }

    &--is-visible{
      @include menu-not-collapsed-down{
        transform: translateY(0%);
        opacity: 1;
        background: $body-bg;
      }
    }
  }

  &__link{
    padding-top: 10px;
    padding-bottom: 10px;
    display: inline-block;
    text-transform: uppercase;
    color: $color-link-nav--active;

    position: relative;

    &::before{
      content: "";
      position: absolute;
      bottom: 5px;
      left: 50%;
      right: 50%;

      height: 1px;
      background-color: $theme-accent;

      transition: all 0.3s;
    }
  }

  &__item{
    font-size: 1rem;
    line-height: 1.333333;
    font-weight: $weight-normal;
    transition: none;
    margin-top: 10px;
    margin-bottom: 10px;

    @include menu-not-collapsed{
      margin-top: 15px;
      margin-bottom: 15px;
      font-size: 0.75rem;
    }

    &:hover{
      color: $color-link--hover;

      .c-main-nav__link::before{
        left: 0;
        right: 0;
      }
    }
  }

  &__bars{
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;

    line-height: 0;
    font-size: 0;

    cursor: pointer;

    visibility: visible;
    opacity: 1;
    transition: all 0.3s;
    z-index: 101;

    @include menu-not-collapsed{
      visibility: hidden;
      opacity: 0;
    }

    &>span{
      position: absolute;
      display: inline-block;
      width: 100%;
      height: 3px;
      background: $color-link;
      transition: all 0.5s;
      &:nth-child(1){
        top: 8px;
      }
      &:nth-child(2){
        top: 15px;
      }
      &:nth-child(3){
        top: 22px;
      }
    }

    &--is-toggled{

      &>span{
        &:nth-child(1){
          top: 15px;
          transform: rotate(45deg);
        }
        &:nth-child(2){
          display: none;
        }
        &:nth-child(3){
          top: 15px;
          transform: rotate(-45deg);
        }
      }
    }
  }
}
