//////////////////////////////////////////////////////////////////////////////////////////
// Fonts

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@function calcFluidFontSize($f-min, $f-max, $w-min, $w-max, $units: px) {
  $f-min: strip-unit($f-min);
  $f-max: strip-unit($f-max);
  $w-min: strip-unit($w-min);
  $w-max: strip-unit($w-max);

	$k: ($f-max - $f-min)/($w-max - $w-min);
	$b: $f-min - $k * $w-min;

	$b: $b + $units;

	@return calc( #{$k} * 100vw + #{$b} );
}

@mixin fluidFontSize($f-min, $f-max, $w-min, $w-max, $fallback: false) {

  font-size: $f-min;

  @media (min-width: $w-min) {
    @if ($fallback) {
      font-size: $fallback;
    }
    font-size: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);
  }
  @media (min-width: $w-max) {
    font-size: $f-max;
  }
}

//////////////////////////////////////////////////////////////////////////////////////////
// Grid
// Point of UNcollapsing menu
@mixin menu-not-collapsed {
  @media (min-width: 900px) { @content; }
}
@mixin menu-not-collapsed-down {
  @media (max-width: 900px) { @content; }
}
// Regular breakpoints
@mixin phone-only {
  @media (max-width: 599px) { @content; }
}
@mixin tablet-portrait-up {
  @media (min-width: 600px) { @content; }
}
@mixin tablet-portrait-down {
  @media (max-width: 600px) { @content; }
}
@mixin tablet-portait-only {
  @media (min-width: 600px) and (max-width: 899px) { @content; }
}
@mixin tablet-landscape-up {
  @media (min-width: 900px) { @content; }
}
@mixin tablet-landscape-down {
  @media (max-width: 900px) { @content; }
}
@mixin tablet-landscape-only {
  @media (min-width: 900px) and (max-width: 1199px) { @content; }
}
@mixin desktop-up {
  @media (min-width: 1200px) { @content; }
}
@mixin desktop-only {
  @media (min-width: 1200px) and (max-width: 1799px) { @content; }
}
@mixin big-desktop-up {
  @media (min-width: 1800px) { @content; }
}
