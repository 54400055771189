.c-timeline{

  &__list{
    list-style: none;
    overflow: hidden;
  }

  &__item{
    padding-left: 100px;
    padding-bottom: 40px;
    padding-right: 15px;

    @include tablet-portrait-up{
      padding-left: 120px;
      padding-bottom: 50px;
      padding-right: 65px;
    }

    &:last-child{
      padding-bottom: 20px;
    }
  }

  &__header{
    position: relative;
  }

  &__heading{
    margin-bottom: 10px;
  }

  &__time{
    position: absolute;
    top: 4px;
    left: -90px;

    font-size: 0.75rem;
    line-height: 1.5;
    font-weight: $weight-bold;
    font-family: $font-headings;

    @include tablet-portrait-up{
      left: -120px;
    }
  }

  &__line{
    position: absolute;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: $theme-accent;

    top: 5px;
    left: -20px;

    @include tablet-portrait-up{
      left: -35px;
    }

    &::before{
      content: "";
      position: absolute;
      display: block;
      width: 2px;
      background: $theme-accent;
      height: 9999px;

      top: 0;
      left: 50%;
      margin-left: -1px;
    }
  }

  &__body{

  }
}
