// Layut helpers
.o-vertical-stretch{

}

.o-main{

}

// Grid system
.o-container{

  width: 100%;
  padding-left: 15px;
  padding-right: 15px;

  @include tablet-portrait-up{
    margin: 0 auto;
  }
  @include tablet-landscape-up{

  }
  @include desktop-up{
    max-width: 1200px;
  }
}

.o-screen-about{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: stretch;

  &__col{
    flex-basis: 100%;
    @include tablet-portrait-up{
      flex-basis: 50%;
    }
  }
}

.o-screen-education{

  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;

  &__col{
    flex-basis: 100%;
    @include tablet-portrait-up{
      flex-basis: 60%;
    }
    @include tablet-landscape-up{
      flex-basis: 55%;
    }

    &--narrow{
      flex-basis: 100%;
      @include tablet-portrait-up{
        flex-basis: 40%;
      }
      @include tablet-landscape-up{
        flex-basis: 30%;
      }
    }
  }
}

.o-screen-research{

  display: flex;

  &__col{
    flex-basis: 100%;
    @include menu-not-collapsed{
      flex-basis: 85%;
    }
  }

  &__cards{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &>*{
      flex-basis: 100%;
      @include tablet-portrait-up{
        flex-basis: calc(50% - 3px);
      }
      @include desktop-up{
        flex-basis: calc(33.3333333% - 3px);
      }
    }
  }
}

.o-screen-publications{

  display: flex;

  &__col{
    flex-basis: 100%;
    @include menu-not-collapsed{
      flex-basis: 85%;
    }
  }
}

.o-screen-teaching{

  display: flex;

  &__col{
    flex-basis: 100%;
    @include menu-not-collapsed{
      flex-basis: 75%;
    }
  }
}

.o-screen-contact{

  margin-bottom: 70px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  @include menu-not-collapsed{
    flex-direction: row;
  }

  &__col-info{
    margin-bottom: 30px;
    @include menu-not-collapsed{
      margin-bottom: 0;
      flex-basis: 35%;
    }
  }
  &__col-form{
    @include menu-not-collapsed{
      flex-basis: 40%;
    }
  }
}
